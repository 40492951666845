.arka-taraf-birimler-buton {
  border: 1px solid #F7FBFF;
  padding: 2px 10px;
  border-radius: 2px;
  font-size: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 5px;
}


.arka-taraf-birimler-cerceve {
  width: 90%;
  margin-bottom: 5px;
  min-width: 242px;
  color: #F7FBFF;
}

.arka-taraf-birimler-alt-cerceve {
  overflow: hidden;
  border-radius: 5px;
  transition: all 0.5s;
}

.arka-taraf-birimler-ust-cerceve {
  height: 30px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.arka-taraf-birimler-icerik {
  height: 110px;
  border-radius: 5px;
  width: 97%;
  margin: auto;
  font-size: 12px;
  padding-top: 5px;
}

.arka-taraf-birimler-icerik2 {
  height: auto;
  border-radius: 5px;
  width: 97%;
  margin: auto;
  font-size: 12px;
  padding-top: 5px;
}

.arka-taraf-birimler-buton-cerceve {
  display: flex;
  justify-content: flex-end;
  min-width: 242px;
}

.arka-taraf-birimler-input {
  display: flex;
  margin-bottom: 2px;
  justify-content: space-between;
  min-width: 242px;
}

.arka-taraf-birimler-input2 {
  outline: none;
  border: none;
  border-radius: 3px;
  color: #F7FBFF;
  font-size: 12px;
  height: 16.5px;
  transition: all 0.5s;
}

.container {
  display: flex;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.out-circle {
  border: 1px solid #F7FBFF;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin: 2px
}

.inner-circle {
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin: 1px;
  background-color: #F7FBFF;
}

.circles-div{
  display: flex;
  justify-content: center;
  margin: 2px;
}