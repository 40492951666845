.tema-bir-cihaz-kart {
    width: 288px;
    margin: 10px;
}

.tema-bir-cihaz-kart-baslik {
    width: 288px;
    height: 57px;
    background-color: #005FAB;
    border-radius: 10px 10px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.tema-bir-cihaz-kucultme-ico {
    font-size: 18px;
    margin: 0px 10px 0px 0px;
    color: #F7FBFF;
    cursor: pointer;
}

.tema-bir-cihaz-icerik-penceresi {
    width: 288px;
    background-color: #F7FBFF;
    transition: all 0.5s;
    display: flex;
    overflow: hidden;
    border-radius: 0px 0px 10px 10px;
}

.tema-bir-cihaz-gorseli {
    position: relative;
    width: 35%;
    height: 320px;
    border-right: 1px solid #d0d6db;
    margin: 25px 0px 25px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.tema-bir-cihaz-durum-ico{
    font-size: 250px;
    left: 75%;
    bottom: 0;
    top: 10px;

}

.tema-bir-cihaz-durum-ico-div {
    position: absolute;
    width: 150px;
    height: 150px;
    opacity: 0.3;
    z-index: 2;
}

.tema-bir-cihaz-durum-ico-slash {
    position: absolute;
    font-size: 250px;
    left: 75%;
    bottom: 0;
    top: 10px;

}

.tema-bir-cihaz-cihaz-bilgileri-penceresi {
    width: 60%;
    height: 314px;
    margin: 28px auto 28px 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.tema-bir-cihaz-bilgileri-cihaz {
    display: flex;
    line-height: 12px;
    font-size: 12px;
    height: 20px;
    border-bottom: solid 1px #d0d6db;
    align-items: center;
    width: 90%;
    overflow: hidden;
}

.tema-bir-cihaz-bilgileri-txt {
    display: flex;
    line-height: 12px;
    font-size: 12px;
    height: 20px;
    border-bottom: solid 1px #d0d6db;
    align-items: center;
    width: 90%;
}

#tema-bir-baslik-txt {
    color: #F7FBFF;
    font-size: 12px;
    margin-left: 10px;
}

#tema-bir-baslik-ico {
    display: flex;
    align-items: center;
}

.thumb {
    transform: rotate(45deg);
}

.tema-bir-baslik-icos {
    display: flex;
    justify-content: center;
}