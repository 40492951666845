.arka-pencere {
    overflow-y: scroll;
    width: 96%;
    height: 97%;
    margin: 20px 0px 5px 7px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
}

.arka-pencere::-webkit-scrollbar {
    width: 7px;
}

.arka-pencere::-webkit-scrollbar-track {
    background-color: transparent;
}

.arka-pencere::-webkit-scrollbar-thumb {
    background: #F7FBFF;
    border-radius: 10px;
}

.arka-pencere::-webkit-scrollbar-thumb:hover {
    background: #cacccf;
}

/* .tooltip {
    display: inline;
    position: relative;
}

.tooltip:hover:after {
    background: #333;
    background: rgba(0, 0, 0, .8);
    border-radius: 5px;
    bottom: 26px;
    color: #fff;
    content: attr(title);
    left: 20%;
    padding: 5px 15px;
    position: absolute;
    z-index: 98;
    width: 220px;
} */