.backButtonDiv {
    align-self: flex-start;
    margin: 25px 0 0 25px;
}

.backButtonStyle {
    font-size: 16px;
    padding: 10px;
    box-shadow: '0 5px 10px rgba(0, 0, 0, 0.15)';
    transition: 'all 0.2s ease',
}

.backButtonStyle:hover {
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.25);
    transform: translateY(-2px);
}

.backButtonStyle:active {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transform: translateY(2px);
}