.login-screen{
    height: 100vh;
    width: 100vw;
    display: flex;
    background-color: #42494f;
}

::placeholder {
    color: #F7FBFF;
    opacity: 0.8;
  }
  
:-ms-input-placeholder {
color: #F7FBFF;
}

::-ms-input-placeholder {
color: #F7FBFF;
}

#login-screen-pencere{
    position: relative;
    height: 400px;
    max-width: 400px;
    background-color: #E4E7EB;
    margin: auto;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.8) 0px 4px 12px;
    display: flex;

    justify-content: center;
    align-items: center;

    flex-direction: column;
    justify-content: center;
}

#login-screen-img{
    height: 50px;
    position: absolute;
    top: 30px;
}

.modal__btn_disabled{
    padding: 1rem 1.6rem;
    border: 1px solid hsla(0, 0%, 100%, .4);
    border-radius: 100rem;
    color: inherit;
    background: transparent;
    font-size: 1.1rem;
    font-family: inherit;
    letter-spacing: .2rem;
    transition: .2s;
    cursor: pointer;
}

.modal__btn_disabled:hover,
.modal__btn_disabled:focus {
  border-color: hsla(0, 0%, 100%, .6);
  background-color: rgb(42, 49, 45);
  transform: translateY(-.2rem);
  cursor: not-allowed;
}

.kod-gonder-buton-div{
    display: flex;
    width: 70%;
    align-items: center;
    flex-direction: column;
}
.login-screen-input{
    width: 250px;
    height: 25px;
    border-radius: 5px;
    outline: none;
    border: none;
    padding-left: 5px;
    margin-bottom: 10px;
    color: #E4E7EB;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    background-color: #465569;
}

.login-screen-input::placeholder{
    color: #bdbec0;
}

#login-screen-giris-buton{
    margin-top: 25px;
    display: flex;
    justify-content: center;
}

#login-screen-giris-buton > span{
    padding: 5px 12px;
    border: #41647D 1px solid;
    border-radius: 6px;
    font-size: 14px;
    color: #263B4A;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    cursor: pointer;
}

.sifre-sifirla-mail-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 105%;
    row-gap: 15px;
}

#beni-hatirla-ls{
    margin-top: 10px;
    display: flex;
    color: #41647D;
    align-items: center;
    font-size: 13px;
    cursor: pointer;
    width: -webkit-fill-available;
    justify-content: space-between;
    align-self: flex-start;
}

#beni-hatirla > input{
    cursor: pointer;
}

#sifre-ico{
    position: absolute;
    top: 16px;
    right: 5px;
    color: white;
    cursor: pointer;
    font-size: 14px;
}


.login-buttons{
    display: flex;
    column-gap: 15px;
}



#login-container{
    margin-top: 20px;
    display: flex;
    width: 720px;
    justify-content: space-between;
}
.login-frame{
    box-sizing: border-box;
    width: -webkit-fill-available;
    width: 100%;
    background-color: rgb(202, 203, 204);
    overflow: hidden;
    height: inherit;
    z-index: 2;
    box-shadow: rgba(0, 0, 0, 0.8) 0px 4px 12px;
    border-radius: 20px;
}

#login-screen-div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 80px;
}
.login-frame-to-right{
    transform: translate(0px, 0px);
    animation: change-login-right 1s;
}
.login-frame-to-left{
    transform: translate(calc(-50% - 30px), 0px);
    animation: change-login-left 1s;
    
}

.sifre-degistir-ilk-span{
    width: 190px;
}

.sifre-degistir-div{
    display: flex;
}

@keyframes change-login-left {
    0%{
        transform: translate(0px, 0px);
    }
    50%{
        transform: translate(-60%, 0px);
    }
    100%{
        transform: translate(calc(-50% - 30px), 0px);
    }
    
}

@keyframes change-login-right {
    0%{ 
        transform: translate(calc(-50% - 30px), 0px);
    }
    50%{
        transform: translate(20%, 0px);
    }
    100%{
        transform: translate(0px, 0px);
    }   
}

.screen-chose-button-container{
    position: relative;
    display: flex;
    justify-content: space-around;
    margin-top: 110px;
}
.screen-chose-button{
    margin: -0.3em -0.15em;
    background-color: rgb(228, 231, 235);
    color: #263B4A;
    border: none;
    font-size:20px;
    border-bottom: 6px rgb(202, 203, 204) solid;
    padding: 0.5em 0.75em 0.3em;
}
.screen-chose-button-selected{
    background-color: rgb(202, 203, 204);
    z-index: 1;
}

.giris-button {
    border-radius: 18px 0 0 0;

}
.giris-button-selected {
    box-shadow: rgba(51, 51, 51, 0.6) -3px -2px 10px;
}

.kayit-button {
    border-radius: 0 18px 0 0;   
}
.kayit-button-selected {
    box-shadow: rgba(51, 51, 51, 0.6) 3px -2px 10px;
}

.phone-input{
    background-color: rgb(70, 85, 105);
    padding: 5px;
    width: 247px;
    border-radius: 5px;
    margin-bottom: 10px;
}

.PhoneInputInput{
    color: aliceblue;
    background-color: rgb(70, 85, 105);
    border: none;
}

.info-icon{
    cursor: pointer;
    margin-top: -8px;
}

.icolu-input-div{
    display: flex;
    align-items: center;
}

.sifre-unuttum-buton{
    display: flex;
    color: #41647D;
    align-items: flex-end;
    font-size: 13px;
    cursor: pointer;
    align-self: flex-end;
}

@media only screen and (max-width: 410px)  {
    #login-screen-img{
        height: 45px;
        top: 30px;
    }
}

@media only screen and (max-width: 350px)  {
    #login-screen-img{
        height: 40px;
        top: 35px;
    }
    .login-screen-input{
        width: 230px;
        height: 25px;
    }
    #login-screen-div{
        margin-left:30px;
    }
    .phone-input{
        width: 228px;
    }
    #login-container{
        width: 640px;
    }
    .icolu-input-div{
        margin-left: -30px;
    }
}

@media only screen and (max-width: 300px)  {
    #login-screen-img{
        height: 35px;
        top: 40px;
    }
    .login-screen-input{
        width: 220px;
        height: 25px;
    }
}