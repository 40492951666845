.on-taraf-role-cerceve{
    border: 1px solid #F7FBFF;
    width: 140px;
    height: 40px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 4px;
    cursor: pointer;
}

.on-taraf-role-orta-kisim{
    display: flex;
    flex-direction: column;
    font-size: 10px;
    text-align: center;
    color: #F7FBFF;
}

.on-taraf-role-ico{
    height: 18px;
    margin-right: 5px;
}