.nova-bilgi-kart-altx{
    width: inherit;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.nova-bilgi-kart-alt-tabletx{
    width: inherit;
    display: flex;
    flex-direction: column;
    margin-left: 335px;
    row-gap: 15px;
}

.nova-bilgi-kart-alt-mobilx{
    width: inherit;
    display: flex;
    flex-direction: column;
    margin-left: 135px;
    row-gap: 15px;
}