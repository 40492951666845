.tema-iki-yukleme-efekti::before{
    content: "";
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to right, #fff0, #ddddddb0, #fff0);
    position: absolute;
    animation: fo 1s linear infinite;
}

.tema-iki-yukleme-efekti{
    background-color: #dddddd60;
    position: relative;
    overflow: hidden;
    border-radius: 7px;
}

@keyframes fo{
    0%{
       left: -100%; 
    }
    100%{
        left: 110%;
    }
}

#tema-iki-cihaz-bilgi-yukleme{
    width: 100%;
    height: 85%;
    margin: 4px 15px;
}

.tema-iki-cihaz-bilgi-durum-yukleme{
    width: 45px;
    height: 45px;
    margin: 7px 0px;
}

