.tema-bir-yukleme-efekti::before{
    content: "";
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to right, #fff0, #fff, #fff0);
    position: absolute;
    animation: fo 0.8s linear infinite;
}

.tema-bir-yukleme-efekti{
    background-color: #ddd;
    position: relative;
    overflow: hidden;
}

@keyframes fo{
    0%{
       left: -100%; 
    }
    100%{
        left: 110%;
    }
}

#tema-bir-cihaz-yukleme-baslik-efekt{
    height: 10px;
    width: 70%;
    margin-left: 10px;
    border-radius: 2px;
}

#tema-bir-cihaz-yukleme-gorsel-efekt{
    height: 60px;
    width: 60px;
    border-radius: 8px;
}

#tema-bir-cihaz-yukleme-cihaz-bilgi-cihaz{
    height: 10px;
    width: 90px;
    margin: auto auto;
    border-radius: 2px;
}


.tema-bir-cihaz-yukleme-cihaz-bilgi-txt{
    height: 10px;
    display:inline-block;
    width: 60px;
    border-radius: 2px;
}
