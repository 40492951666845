.salt-alt {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
}

.salt-role {
    display: grid;
    grid-template-columns: 1fr 0.5fr 0.8fr; /* 3 sütunlu yapı */
    gap: 10px;
    font-size: 14px;
}

.sensor-values {
    display: grid;
    grid-template-columns: 1fr 1fr; /* 2 sütunlu yapı */
    gap: 10px;
    font-size: 14px;
    text-align: left;
}

.grid-header {
    font-weight: bold;
    display: contents;
}

.grid-row {
    display: contents;
}

.hr1 {
    grid-column: span 3;
    border: 0;
    height: 1px;
    background-color: #ccc;
}

.hr2 {
    grid-column: span 2;
    border: 0;
    height: 1px;
    background-color: #ccc;
}

.salt-uretim {
    display: grid;
    align-items: center;
    justify-items: stretch;
    justify-content: center;
}

.vertical-divider {
    width: 1px;
    background-color: #ccc;
    height: auto;
    margin: 0 10px;
}

.salt-uretim-inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.salt-role-inner{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.salt-uretim > .calisma-modux {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}