.tema-iki-cihaz-cerceve {
    height: 128px;
    width: 318px;
    color: #F7FBFF;
    display: flex;
    border-radius: 10px;
    align-items: center;
    background-color: #495579;
    transition: all 0.5s;
    box-shadow: none;
    margin: 8px 0px;
}

.tema-iki-cihaz-bilgisi {
    width: 45%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tema-iki-cihaz-bilgi-durum {
    width: 55%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    line-height: 8px;
    justify-items: center;
    margin-right: 5px;
}
.tema-iki-cihaz-bilgi-durum-nova {
    width: 50%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    line-height: 8px;
    justify-items: center;
    margin-right: 5px;
}
.tema-iki-cihaz-bilgi-durum-nova-link {
    display: flex; 
    justify-content: center;
    align-items: center;
    border: 1px solid #FFFBEB;
    border-radius: 8px;
    flex-direction: column;
    width: 40px; 
    height: 70px;
    padding: 5px;
    margin-top: 10px;
    margin-right: 5px;
    color: #F7FBFF;
    text-decoration: none;
}
#tema-iki-cihaz-bilgi {
    height: 85%;
    width: 100px;

    padding: 8px;
    margin-top: -25px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    font-size: 9px;
    color: #F7FBFF;
    text-decoration: none;
}

#tema-iki-cihaz-bilgi-nova {
    height: 85%;
    width: 100px;

    padding: 15px 8px;
    margin-top: -40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    font-size: 9px;
    color: #F7FBFF;
    text-decoration: none;
}

.tema-iki-cihaz-bilgi-txt-temel {
    margin: 4px 0px;
    line-height: 8px;
}

.tema-iki-pin {
    display: flex;
    align-items: flex-start;
    height: inherit;
    margin-top: 10px;
    margin-right: 5px;
    cursor: pointer;
}
.tema-iki-nova-logo{
    margin-left: -20px;
    /* opacity: 0.7; */
}
.tema-iki-omnicon-logo{
    margin-left: 5px;
    /* opacity: 0.8; */
}

.tema-iki-cihaz-bilgi-durum-nova-link-div{
    display: grid;
    height: 50px;
    justify-items: center;
}