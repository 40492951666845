.infopanel-container {
    background-color: rgb(56, 56, 56);
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    box-sizing: border-box;
    margin: 5px;
    border: 5px red dashed;
}

.infopanel-valueDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 86px;
    height: 100%;
    color: red;
    text-align: center;
}

.infopanel-isimDiv {
    position: absolute;
    bottom: 0;
    left: 0;
    color: rgb(35, 121, 196);
    font-size: 25px;
    padding: 10px;
    margin: 0;
}

.back-button {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: transparent;
    color: white;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 18px;
    border-radius: 5px;

    z-index: 1000;
    /* Butonun her şeyin üzerinde olmasını sağlar */
}

.back-button:hover, .full-screen-button:hover {
    background-color: rgba(0, 0, 0, 0.7);
}

.datetime-container {
    position: absolute;
    bottom: 10px;
    right: 10px;
    color: white;
    font-size: 32px;
}

.full-screen-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    color: white;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 20px;
    border-radius: 5px;
    z-index: 1000;
}