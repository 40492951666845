.lisans-box {
    background-color: #42494f;
}

.lisans{
    margin: 10px;
    border-radius: 5px;
    border: 2px solid slategrey;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items:center;
    color: aliceblue;
}

button{
    background-color: #42494f;
    color: aliceblue;
    border: 1px solid #F7FBFF;
    padding: 3px 8px;
    border-radius: 5px;
    align-items: center;
    cursor: pointer;
    margin: 5px;
}
.lisansAlt {
    display: flex;
    flex-wrap: wrap;
}
.lisansAlt>div {
    width: 275px;
}