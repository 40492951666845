.calisma-modu{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 15px;
}

.nova-icos{
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.nova-icos>img {
    border: 1px solid aliceblue;
    padding: 13px;
    border-radius: 5px;
}

.ppm-durum{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.kapasite-limit {
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 5px;
}

.nova-bilgi-kart-ust{
    margin-top: -20px;
}

.nova-bilgi-kart-alt{
    margin-top: 20px;
}