.menu{
    grid-area: menu;
    background-color: #2D353C;
    color: #F7FBFF;
}

#menuKullaniciPenceresi{
    background-image:  linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("../assets/img/menu-img.jpg");
    background-size: cover;
    cursor: pointer;
    transition: all 0.5s;
    display: flex;
    flex-direction: column;
}

#menuKullaniciPenceresi:hover{
    background-image:  linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.6)), url("../assets/img/menu-img.jpg");
    background-size: cover;
}

.yan-menu-item{
    height: 44px;
    margin: 5px auto 0 auto;
    border-radius:10px;
    transition: 0.5s;
    cursor: pointer;
    display: flex
}

.menu-item:hover{
    background-color: white;
    color: #2D353C;
}

.menu-item-selected{
    background-color: white;
    color: #2D353C;
}

.menu-item-selected-hover{
    background-color: rgb(129, 129, 129);
    color: #2D353C;
}

#yan-menu-div-temel{
    overflow: hidden;
    transition: all 0.5s;
    width: 100%;
    border-bottom: 1px solid #1d1d1d;
    display: flex;
    flex-direction: column;
}

.yan-menu-div{
    width: 90%;
    margin: 0px auto;
}
.yan-menu-link{
    text-decoration: none;
    color: #F7FBFF;
    font-size: 14px;
    cursor: pointer;
}

.yan-menu-icon{
    font-size: 14px;
    margin: auto 10px auto 10px;
}

.yan-menu-ayar{
    margin: 5px auto 0 auto;
    width: 90%;
    height: 44px;
    border-radius:10px;
    transition: 0.5s;
    display: flex;
    font-size: 14px;
}

.yan-menu-ayar > p{
    margin-left: 10px;
    /* width: 115px; */
    overflow: hidden;
}

#cihazlar-ana-div{
    text-decoration: none;
    color:#F7FBFF;
    width: 90%;
    margin: 0px auto;
    overflow: hidden;
    background-color: transparent;
    font-size: 14px;
}

.yan-menu-cihazlar-pencere{
    height: 44px;
    margin: 0px auto;
    transition: all 0.5s;
    cursor: pointer;
    display: flex;
}

.yan-menu-cihazlar-pencere > div{
    margin: auto 0px;
    position: relative;
    width: 10.5px;
    height: 14px;
    margin-right: 20px;
    display: flex;
    align-items: center;
}

.yan-menu-cihazlar-pencere-link, .yan-menu-cihazlar-pencere-link2{
    transition: all 0.5s;
    position: absolute;
}

.yan-menu-cihazlar-pencere-link2{
    font-size: 10px;
}

.yan-menu-cihazlar-pencere > p{
    transition: all 0.5s;
    font-size: 14px;
    overflow: hidden;
}

