.gruplarBox {
    background-color: #42494f;
    color:aliceblue;
    display: flex;
    flex-wrap: wrap;
}

.grup {
    margin: 10px;
    border-radius: 5px;
    border: 2px solid slategrey;
    display: flex;
    flex-direction: column;
    color: aliceblue;
    height: max-content;
    width: 400px;
    align-items: stretch;
    padding: 5px
}

.grup > span > div {
    width: 125px;
    display: inline-block;
}

.grup > span > em {
    overflow-wrap: break-word;
}

.editGrup {
    border: 2px dotted red;
}