 #profil{
    background-color: #42494f;
    overflow-y: scroll;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    padding: 0px 5%;
}

.profil-alt-div{
    margin: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px  inset;
    background-color: #8397A6;
    border-radius: 15px;
    transition: all 0.5s;
}

.profil-alt-div-hesap {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    background-color: transparent;
    border-radius: 8px;
    transition: background-color 0.3s ease;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    height: 30px;
  }
  
  .profil-alt-div-hesap:hover {
    background-color: #d0d0d0;
  }

  .profil-div-title {
    font-weight: 600;
    font-size: 18px;
    margin-left: 10px;
    color: #333;
  }
  
  .profil-div-menu {
    padding: 15px;
    background-color: transparent;
    border-radius: 8px;
    overflow: hidden;
    transition: height 0.3s ease, margin-top 0.3s ease;
  }
  
  .profil-flex-column {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
  
  .profil-flex-text {
    font-weight: 500;
    color: #333;
    margin-bottom: 5px;
  }
  
  .profil-input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    transition: border-color 0.3s ease;
  }
  
  .profil-input:focus {
    border-color: #007bff;
    outline: none;
  }
  
  .profil-guncelle-buton {
    padding: 10px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .profil-guncelle-buton:hover {
    background-color: #0056b3;
  }
  
  /* .profil-sifre-ico {
    position: absolute;
    right: 10px;
    top: 35px;
    cursor: pointer;
    color: #666;
  } */
  .profil-sifre-ico{
    position: relative;
    width: 20px;
    top: -30px;
    left: 180px;
    cursor: pointer;
}
  
  .profil-user-ico {
    font-size: 35px;
    color: #007bff;
  }
  
  @media (max-width: 768px) {
    .profil-alt-div-hesap {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .profil-flex-column {
      margin-bottom: 15px;
    }
  
    .profil-input {
      width: 100%;
    }
  }
  
  @media (max-width: 576px) {
    #profil {
      padding: 10px;
    }
  
    .profil-div-title {
      font-size: 16px;
    }
  
    .profil-alt-div-hesap {
      padding: 8px 10px;
    }
  
    .profil-guncelle-buton {
      width: 100%;
    }
  }
  