.bottom-menu{
    background-color: #2D353C;
    color: #F7FBFF;
}

.alt-menu-item-selected{
    background-color: white;
    color: #2D353C;
}

.alt-menu-item-selected-hover{
    background-color: rgb(129, 129, 129);
    color: #2D353C;
}

.alt-menu-buton{
    width: 80px;
    margin: 0px auto;
}

.alt-menu-link{
    text-decoration: none;
    color: #F7FBFF;
}

.alt-menu-buton-div{
    height: 35px;
    border-radius:10px;
    transition: 0.5s;
    cursor: pointer;
    display: flex;
    justify-content: center;
}

.alt-menu-ico{
    font-size: 14px;
    margin: auto 10px auto 10px;
}

#alt-menu-buton-cihazlar{
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 35px;
    border-radius: 10px;
    transition: 0.5s;
    cursor: pointer;
    display: flex;
    justify-content: center;
}



.alt-menu-buton-cihazlar-sekme{
    width: 80px;
    height: 35px;
    position: absolute;
}


.alt-menu-buton-cihazlar-sekme-div{
    height: 35px;
    margin: 0px auto;
    border-color: #F7FBFF;
    transition: 0.5s;
    cursor: pointer;
    display: flex;
    justify-content: center;
}

.alt-menu-buton-cihazlar-sekme-div > div{
    margin: auto 0px;
    position: relative;
    width: 10.5px;
    height: 14px;
    margin-right: 20px;
    display: flex;
    align-items: center;
}

.alt-menu-buton-cihazlar-sekme-ico-kare{
    transition: all 0.5s;
    font-size: 16px;
    margin: auto 10px auto 10px;
    position: absolute;
}

.alt-menu-buton-cihazlar-sekme-ico-rakam{
    font-size: 12px;
    position: absolute;
}


