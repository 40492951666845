.analog-cikis-temel-pencere{
    width: 165px;
    height: 40px;
    display: flex;
    border: 1px solid #F7FBFF;
    border-radius: 5px;
    justify-content: space-evenly;
    margin-top: 4px;
    cursor: pointer;
}

.analog-cikis-deger-txt{
    display: flex;
    flex-direction: column;
    color: #F7FBFF;
    font-size: 10px;
    justify-content: center;
    align-items: center;
}

.analog-cikis-yuzde{
    width: 100px;
    height: 40px;
    display: flex;
    flex-direction: column;
    position: relative;
}

.analog-cikis-yuzde-pozisyon{
    border: 1px solid #F7FBFF;
    display: flex;
    position: absolute;
    height: 10px;
    font-size: 8px;
    padding: 1px;
    align-items: center;
    margin-top: 1px;
    border-radius: 2px;
    color: #F7FBFF;
    transition: 0.5s;
}

.analog-cikis-yuzde-bar{
    width: 100px;
    height: 10px;
    border-radius: 5px;
    margin: 16px auto 0px auto;
    background-color: #F7FBFF;
}

.analog-cikis-yuzde-bar > div{
    height: 10px;
    border-radius: 5px;
    background-color: #11E8F0;
    transition: 0.5s;
}

.analog-cikis-cihaz-bilgisi{
    font-size: 9px;
    color: #F7FBFF;
    margin: auto auto;
}

