.cihazlar{
    background-color: #42494f;
    overflow-y: scroll;
}

#cihazlar-temel-div{
    width: 100%;
    background-color: #42494f;
    border-radius: 15px 15px 0 0;
}

#cihazlar-filtre-penceresi{
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
}

.cihazlar-sayfa-degistirme-icolari{
    font-size: 20px;
    margin: 10px 10px 0px 10px;
    color: #F7FBFF;
    position: absolute;
    cursor: pointer;
    top: -15px;
}

.cihazlar-sayfa-degistirme-icolari-kucuk-ekran{
    font-size: 20px;
    margin: 10px 10px 0px 10px;
    color: #F7FBFF;
    position: absolute;
    cursor: pointer;
    top: -10px;
}

#buton-filtre{
    height: 70px;
    display: grid;
    grid-template-columns: minmax(63px, 80px) minmax(63px, 80px) minmax(63px, 80px) minmax(63px, 80px) minmax(63px, 80px);
    gap: 10px;
    justify-content: center;
    align-items: center;
}

.buton-filtre-cerceve{
    height: 40px;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
}

.buton-filtre-ico-golge{
    color: rgba(0, 0, 0, 0.7);
    position: absolute;
    top: 12px;
    right: 7px;
}

.buton-filtre-ico{
    color: rgb(209, 209, 209);
    position: absolute;
    top: 10px;
    right: 8px;
}

.buton-filtre-renk-katmani{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    border-radius: 10px;
}

.buton-filtre-yazi{
    color: #F7FBFF;
    text-align: center;
    width: 70%;
    position: absolute;
    top: 0px;
    margin-top: 11px;
}

#arama-filtresi-cihaz-boyu{
    display: flex;
    justify-content: center;
    position: relative;
}

#arama-filtresi{
    position: relative;
    display:flex;
    margin-right: 10px;
}

#arama-filtresi > div{
    width: 160px;
    position: absolute;
}

#arama-filtresi > input{
    outline: none;
    border: 1px solid #2D353C;
    border-radius: 5px;
    width: 150px;
}

#arama-filtresi-ico{
    position: absolute;
    right: 13px;
    top: 4px;
    font-size: 10px;
    color: gray;
}

#tüm-cihaz-boyu-ico{
    font-size: 20px;
    margin: 0px 0px 0px 10px;
    color: #F7FBFF;
}


