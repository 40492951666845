#cihaz-on-taraf{
    height: 232px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

#cihaz-on-taraf-kanal-degeri{
    color: #F7FBFF;
    font-size: 50px;
    text-align:center;
    position:relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height:60px;
}

#cihaz-on-taraf-cikis-birimleri{
    display: flex;
    justify-content: space-evenly;
    margin-top: 6px;
}

.cihaz-on-taraf-cikis-biri{
    display: flex;
    justify-content: center;
    flex-direction: column;
}

#cihaz-on-taraf-grafik-ico{
    align-self: flex-end;
    margin: 0px 10px 8px 0px;
    color: #F7FBFF;
}


