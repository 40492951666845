.cihaz-bilgi-kart-cerceve{
    height: 320px;
    background-color: #005FAB;
    border-radius: 13.4px;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    transition: all 0.5s;
    white-space: nowrap;
    align-items: center;
}

.cihaz-bilgi-kart-popup{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #42494fdd;
    z-index: 1;
    overflow: hidden;
    justify-content: center;
}

.cihaz-bilgi-kart-popup-cerceve{
    width: 320px;
    height: 150px;
    transition: all 0.5s;
    border: 1px solid #F7FBFF;
    border-radius: 20px;
    position: absolute;
    display: flex;
}

.cihaz-bilgi-kart-popup-gorsel{
    height: 70px;
    margin: 4px 0px 0px 3px;
    position: absolute;
    right: 20px;
    top: -40px;
}

.cihaz-bilgi-kart-popup-gorsel-golge{
    height: 100px;
    width: 100px;
    position: absolute;
    right: 8px;
    top: -10px;
    display: flex;
    justify-content: center;
    align-items: center;
    perspective: 800px;
}

.cihaz-bilgi-kart-popup-gorsel-golge > div{
    height: 40px;
    width: 40px;
    background-color: rgba(0, 0, 0, 0.2);
    transform: rotateX(85deg);
    border-radius: 100%;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 20px 2px;
}

.cihaz-bilgi-kart-popup-icerk{
    display: flex;
    flex-direction: column;
    margin: auto 0px auto 20px;
    color: #F7FBFF;
    height: 100px;
    justify-content: center;
    font-size: 14px;
}

.cihaz-bilgi-kart-icerik-cerceve{
    height: 29px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cihaz-bilgi-kart-ust-taraf{
    background-color: #347DB9;
    display: flex;
    border-radius: 15px;
    height: 29px;
    transition: all 0.5s;
}

.cihaz-bilgi-kart-ust-taraf > div{
    border-radius: 100%;
    background-color: #F7FBFF;
    width: 29px;
    height: 29px;
    display: flex;
}

.cihaz-bilgi-kart-ust-taraf > div > span{
    margin: auto;
    font-size: 18px;
}

.cihaz-bilgi-kart-ust-taraf > p{
   margin: auto 15px auto 10px;
   color: #F7FBFF;
}

.cihaz-bilgi-kart-ust-taraf-ico{
    margin-right: 5px;
    color: #F7FBFF;
    cursor: pointer;
}

.kanalIsim {
    color:#F7FBFF;
    position: fixed;
    margin-left: 45%;
}