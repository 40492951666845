
.cihaz-bilgi-kart-icerik-cerceve2{
    height: inherit;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    font-size: 14px;
}

.nova-bilgi-kart-ust{
    width: inherit;
    display: flex;
    flex-direction: row;
}

.nova-isletim-modu {
    margin-left: 15px;
}

.nova-ico-div{
    position: fixed;
    right: 5px;
}


.nova-bilgi-kart-orta{
    display: flex;
    flex-direction: row;
}

.vurus-sayisi{
    margin: 0 15px;
}
.vurus-ust{
    display: flex;
    justify-content: center;
}
.vurus-cizgi{
    width: 110px;
    border: 1px solid aliceblue;
}
.vurus-alt{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 45px;
}

.nova-bilgi-kart-alt{
    width: inherit;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    
}

.calisma-modux {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.calisma-modux>span {
    border: 2px solid aliceblue;
    padding: 10px;
    border-radius: 8px;
    margin-top: 10px;
}
.icos{
    display: flex;
    flex-direction: row;
    column-gap: 50px;
}

.analog{
    display: flex;
    flex-direction: row;
}

.vertical {
    width: 0;
    height: 120px;
    border: 1px solid aliceblue;
}

.analog-vurus-sayisi{
    margin-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.analog-vurus-alt{
    margin-top: 0;
}