.cihaz-ekle-page{
    background-color: #42494f;
    display: flex;

    justify-content: center;
    align-items: center;
}



.cihaz-ekle-container{
    width: 500px;
    height: 500px;
    background-color: #b0b0a985;
    /* background-color: #fffb2b42; NOVA */
    /* background-color: #00abe462; FYT */
    box-shadow: rgba(51, 51, 51, 1) 15px 15px 20px;
    border-radius: 8%;
    display: flex;
    flex-direction: column;
    color: aliceblue;
    align-items: center;
    justify-content: space-evenly;
}


.cihaz-ekle-select{
    background-color: transparent;
    color: aliceblue!important;
    padding: 8px;
    border: 1px solid aliceblue;
    border-radius: 4px;
    width: 250px;
    font-size: 16px;
    text-align: center;
}

.cihaz-bilgi-kart-popup-gorsel-golge-cihaz-ekle {
    height: 200px;
    width: 200px;
    position: absolute;
    margin-top: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cihaz-bilgi-kart-popup-gorsel-golge-cihaz-ekle > div{
    height: 180px;
    width: 180px;
    background-color: rgba(0, 0, 0, 0.2);
    transform: rotateX(85deg);
    border-radius: 100%;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 20px 2px;
}


.cihaz-ekle-select option{
    font-size: 17px;
    background-color: rgba(99, 100, 100, 0.767);
    border: none;
    padding-bottom: 0;
}

.img-transition {
    transition: 0.8s ease-in-out all .5s;
  }


.cihaz-ekle-select:focus{
    outline: none;
}

.inputContainer{
    display: flex;
    column-gap: 10px;
    z-index: 999;
}

.cod{
    width: 50px;
    height: 50px;
    border: 1px solid aliceblue;
    border-radius: 20%;
}
.cod>input{
    width: 40px;
    height: 40px;
    background-color: transparent;
    color: aliceblue;
    border: none;
    border-radius: 20%;
    margin: 4px;
    font-size: 25px;
    padding-left: 15px;
}

.cod>input:focus{
    outline: none;
}

.cihaz-ekle-icerik{
    font-size: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    row-gap: 15px;
}

.cihaz-ekle-button{
    background-color: transparent;
    padding: 8px;
}

.cihaz-ekle-button:hover{
    background-color: green;
}


.kurulum-yapiniz-div{
    display: flex;
    flex-direction: column;
    color: antiquewhite;
    justify-content: center;
    align-items: center;
    row-gap: 10px;
    border: 1px solid red;
    border-radius: 8px;
    padding: 25px 20px;
}

.warningIco{
    color:red;
    font-size: 60px;
}

.deviceImg{
    height: 180px;
}

@media only screen and (max-width: 522px) {
    .cihaz-ekle-container{
       width: 300px;
    }
    .inputContainer{
        column-gap: 5px;
    }
    .cod{
        width: 40px;
        height: 40px;
    }
    .cod>input{
        width: 20px;
        height: 20px;
        padding: 5px 10px;
    }
    .deviceImg{
        height: 100px;
    }
    .cihaz-bilgi-kart-popup-gorsel-golge-cihaz-ekle{
        height: 150px;
        width: 150px;
        margin-top: 150px;
    }
    .cihaz-bilgi-kart-popup-gorsel-golge-cihaz-ekle > div{
        width: 100px;
        height: 100px;
    }
}