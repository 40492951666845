.kartlar-grid {
    display: flex;
    justify-content: center;
    margin-top: 25px;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.nova-bilgi-kart-cerceve{
    color:aliceblue;
    margin-right: 15px;
    margin-top: 15px;
    height: 600px;
    background-color: #005FAB;
    border-radius: 13.4px;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    transition: all 0.5s;
    white-space: nowrap;
    align-items: center;
}

.nova-ico-div {
    margin-top: 16px;
    z-index: 5;
}

.nova-ico-div2 {
    position: fixed;
    top: 25%;
    left: 80%;
    font-size: 30px;
    border: 2px solid aliceblue;
    border-radius: 50%;
    padding: 15px;
    cursor: pointer;
}

.lock {
    z-index: -10;
}

.cihaz-bilgi-kart-ust-taraf-ico2{
    margin: 0;
}

.tablet{
    height: 800px!important;
}

.mobil{
    height: 800px!important;
}

.buttonDiv{
    width: 100%;
    height: 50px;
}

.nova-kaydet-button{
    padding: 5px 10px;
    margin: 10px 15px; 
    background-color: transparent;
    color: aliceblue;
    font-weight: bolder;
}

.modeDiv{
    display: flex;
    flex-direction: row;
}

.editStar {
    color:red;
    font-size: 25px;
}

.star {
    font-size: 25px;
    opacity: 0;
}

.editStar2{
    color:red;
    margin-left: -25px;
}
.nova-manuel-input{
    outline: none;
    border: 2px solid aliceblue;
    border-radius: 8px;
    color: aliceblue;
    background-color: transparent;
    font-size: 45px;
    height: auto;
    width: 160px;
    text-align: center;
    margin-right: 35px;
}

.nova-manuel-alt-input{
    display: inline;
    outline: none;
    border: 2px solid aliceblue;
    border-radius: 5px;
    color: aliceblue;
    background-color: transparent;
    height: auto;
    width: 40px;
    padding-left: 5px;
}

.nova-select-menu{
    outline: none;
    background-color: transparent;
    border: 2px solid aliceblue;
    color: aliceblue;
    width: 60px;
}

.custom-select > select {
    appearance: none;
    box-shadow: none;
    /* Personalize */
    border: 2px solid aliceblue;
    border-radius: 5px;
    outline: none;
    text-align: center;
    cursor: pointer;
    padding: 2px 5px;
    margin-left: 20px;
}

.custom-select > select > option {
    border: 1px solid aliceblue ;
    background-color: #005FAB;
    cursor: pointer;
    font-size: medium;
}

.custom-select {
    position: relative;
    display: flex;
  }

  .custom-select > select::after {
    padding: 5px;
    transition: .25s all ease;
    pointer-events: none;
  }

.alarm-popup{
    width: 300px;
    height: 300px;
    background-color: rgba(128, 128, 128, );
    background: linear-gradient(245deg, rgb(127, 126, 126) 0%, rgb(0, 101, 184) 100%);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.alarm-popup-kapat{
    position: absolute;
    right:10px;
    top: 10px;
    cursor: pointer;
    color:aliceblue
}

.alarm-popup-kaydet{
    position: absolute;
    right:10px;
    bottom: 10px;
    cursor: pointer;
}

.alarmlarTitle{
    color: aliceblue;
    padding-top: 10%;
}

.nova-lock {
    position: fixed;
    pointer-events: none; /* make the component clickable through */
    height: inherit;
    width: inherit;
    background-image: repeating-linear-gradient(
        45deg,
        #005FAB,
        #005FAB 60px,
        aliceblue 45px,
        aliceblue 70px
    );
    opacity: 0.3;
    
}
.nova-ico-lock {
    position: absolute;
    top: 40%;
    left: 40%;
    font-size: 75px;
    border: 5px solid aliceblue;
    border-radius: 50%;
    padding: 20px 25px;
    cursor: pointer;
    opacity: 1;
    z-index: 5;
}