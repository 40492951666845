.ayar-popup{
    width: 30rem;
    padding: 15rem 2rem 4rem 2rem;
    border-radius: .8rem;
    color: hsl(0, 0%, 100%);
    background: linear-gradient(to right bottom, hsl(236, 50%, 50%), hsl(195, 50%, 50%));
    box-shadow: .4rem .4rem 2.4rem .2rem hsla(236, 50%, 50%, 0.3);
    overflow-x: hidden;
    overflow-y: auto;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: grid;
    justify-items: center;
}

.ayar-popup-arkaplan{
    width: 100%;
    height: calc(100% - 50px);
    position: fixed;
    background-color: rgba(127, 126, 126, 0.712);

    bottom: 0;
    z-index: 6;
}
.ayar-popup-kapat{
    position: absolute;
    right:10px;
    top: 10px;
    cursor: pointer;
}

.ayar-popup-kaydet{
    padding: 1rem 1.6rem;
    border: 1px solid hsla(0, 0%, 100%, .4);
    border-radius: 100rem;
  
    color: inherit;
    background: transparent;
    font-size: 1.1rem;
    font-family: inherit;
    letter-spacing: .2rem;
  
    transition: .2s;
    cursor: pointer;
}

.ayar-popup-kaydet:hover{
    border-color: hsla(0, 0%, 100%, .6);
    transform: translateY(-.2rem);
}
.ayar-popup-not{
    position: absolute;
    bottom: 10px;
    left: 30px;
    font-size: smaller;
    text-wrap: balance;
}

.ayar-popup::before {
    content: '';
  
    position: absolute;
    top: 0%;
    left: 100%;
    transform: translate(-50%, -50%);
  
    width: 18rem;
    height: 18rem;
    border: 1px solid hsla(0, 0%, 100%, .2);
    border-radius: 100rem;
  
    pointer-events: none;
  }
.choose-menu-button{
    width: 90px;
    box-sizing: content-box;
    background-color: transparent;
}

.choose-menu-button-selected{
    background-color: green;
}
.choose-menu-secenekler{
    padding:0;
    margin: 0;
    list-style: none;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -60%);
}
.nova-bilgi-kart-orta-unit-selection-button{
    padding: 5px 10px;
    margin: 10px 15px; 
    box-sizing: content-box;
    background-color: transparent;
    color: aliceblue;
    font-size: 40px;
    border: 2px aliceblue solid;
    border-radius: 8px;
}


.ayar-popup-yukleme{
    width: 1800px;
    height: 300px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: absolute;
    transform: translate(calc(-100% + 110px), -40%);
    top:-60px;
    left:0px;
}

@keyframes dalgalanma {
    from{
        transform: translate(0px, 0px);
    }
    to{
        transform: translate(calc(-100% + 110px), -40%);
    }
    
}
.ayar-popup-yukleme-dolu{
    animation:dalgalanma 15s;
}

.ayar-popup-yukleme-container{
    width: 100px;
    height: 100px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 2px solid aliceblue;
    border-radius: 50%;
    overflow: hidden;
}
.ayar-popup-kaydediliyor-metni{
    position: absolute;
    bottom: 130px;
    left: 50%;
    transform: translateX(-50%);
}

.nova-mail-verification-input {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
}

.ayar-popup-title {
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, 0%);
    width: max-content;
}

.ayar-popup-error{
    position: absolute;
    left: 50%;
    bottom: 20%;
    transform: translateX(-50%);
    color: rgb(131, 17, 17);
    font-weight: bold;
    
}

.ayar-nova-countdown{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 26px;
}

.ayar-nova-countdown-lock-icon{
    cursor: pointer;
}

.approval-popup-text::before {
    content: '';
  
    position: absolute;
    top: 0%;
    left: 100%;
    transform: translate(-50%, -50%);
  
    width: 18rem;
    height: 18rem;
    border: 1px solid hsla(0, 0%, 100%, .2);
    border-radius: 100rem;
  
    pointer-events: none;
  }

.approval-popup{
    width: 30rem;
    padding: 9rem 2rem 4rem 2rem;
    border-radius: .8rem;
    color: hsl(0, 0%, 100%);
    background: linear-gradient(to right bottom, hsl(236, 50%, 50%), hsl(195, 50%, 50%));
    box-shadow: .4rem .4rem 2.4rem .2rem hsla(236, 50%, 50%, 0.3);
    overflow-x: hidden;
    overflow-y: auto;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: grid;
    justify-items: center;
}

.approval-popup-text{
    text-align: center;
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid hsla(0, 0%, 100%, .4);
}
.approval-popup-button-approve, .approval-popup-button-reject{
    padding: 1rem 1.6rem;
    border: 1px solid hsla(0, 0%, 100%, .4);
    border-radius: 100rem;
  
    color: inherit;
    background: transparent;
    font-size: 1.1rem;
    font-family: inherit;
    letter-spacing: .2rem;
  
    transition: .2s;
    cursor: pointer;
}

.approval-popup-button-approve:hover, .approval-popup-button-reject:hover {
    border-color: hsla(0, 0%, 100%, .6);
    transform: translateY(-.2rem);
}

@media only screen and (max-width: 520px)  {
    .ayar-nova-countdown{
        top: 75px;
    }
}