.cihaz-onay-page{
    background-color: #42494f;
    color: aliceblue;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.bekleyen-onaylar-kart-grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.bekleyen-onaylar-karti {
    color: hsl(0, 0%, 100%);
    background: linear-gradient(to right bottom, hsl(236, 50%, 50%), hsl(195, 50%, 50%));
    box-shadow: .4rem .4rem 2.4rem .2rem hsla(236, 50%, 50%, 0.3);
    padding: 4rem 2rem;
    border-radius: .8rem;
    margin: 5px;
    padding: 15px;
    display: flex;
    flex-direction: column;

}

.bekleyen-onaylar-kart-detay{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.yetkiler-span{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.bekleyen-onaylar-title{
    text-align: center;
}