.login-popup{
    height: 100vh;
    width: 100vw;
    display: flex;
    position: absolute;
    z-index: 100;
}

::placeholder {
    color: #F7FBFF;
    opacity: 0.8;
  }
  
:-ms-input-placeholder {
color: #F7FBFF;
}

::-ms-input-placeholder {
color: #F7FBFF;
}

.deneme{
    min-width: 400px;
}

#login-screen-pencere{
    position: relative;
    height: 400px;
    max-width: 400px;
    background-color: #E4E7EB;
    margin: auto;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.8) 0px 4px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#login-screen-img{
    height: 50px;
    position: absolute;
    top: 30px;
}

#login-screen-girisi-div{
    display: flex;
    flex-direction: column;
    margin-top: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-screen-input{
    width: 250px;
    height: 25px;
    border-radius: 5px;
    outline: none;
    border: none;
    padding-left: 5px;
    color: #E4E7EB;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    background-color: #465569;
}

#login-screen-giris-buton{
    margin-top: 25px;
    display: flex;
    justify-content: center;
}

#login-screen-giris-buton > span{
    padding: 5px 12px;
    border: #41647D 1px solid;
    border-radius: 6px;
    font-size: 14px;
    color: #263B4A;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    cursor: pointer;
}

#beni-hatirla{
    margin-top: 10px;
    display: flex;
    color: #41647D;
    align-items: center;
    font-size: 13px;
    cursor: pointer;
    align-self: flex-start;
}

#beni-hatirla > input{
    height: 15px; 
    cursor: pointer;
}

#sifre-ico{
    position: absolute;
    top: 16px;
    right: 5px;
    color: white;
    cursor: pointer;
    font-size: 14px;
}

@media only screen and (max-width: 500px) {
    .deneme{
        min-width: 350px;
    }
}

@media only screen and (max-width: 410px)  {
    #login-screen-img{
        height: 45px;
        top: 30px;
    }
    .deneme{
        min-width: 320px;
    }
}

@media only screen and (max-width: 350px)  {
    #login-screen-img{
        height: 40px;
        top: 35px;
    }
    .login-screen-input{
        width: 230px;
        height: 25px;
    }
    .deneme{
        min-width: 300px;
    }
    #login-screen-div{
        margin-left:30px;
    }
}

@media only screen and (max-width: 300px)  {
    #login-screen-img{
        height: 35px;
        top: 40px;
    }
    .login-screen-input{
        width: 220px;
        height: 25px;
    }
}

.butonddd:hover{
    background-color: #e0e0e0;
}