.kullanici-box{
    background-color: #42494f;
    display: flex;
    flex-direction: column;
}

.kullanici-ekleme-box {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin: 5px;
    padding: 5px;
}

.kullanici-ekleme {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: aliceblue;
    height: 300px;
    font-size: 17px;
}

::placeholder{
    color:#42494f;
}

.arka-taraf-birimler-cerceve2 {
    border: 2px solid aliceblue;
    border-radius: 8px;
    margin: 5px 25px;
    min-width: 242px;
    color: #F7FBFF;
}

.arka-taraf-birimler-ust-cerceve2 {
    font-size: larger;
    height: 30px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.textDivs > span > div {
    width: 125px;
    display: inline-block;
}
.PhoneInputCountrySelectArrow {
    color:aliceblue;
}
.arka-taraf-birimler-cerceve3 {
    border: 2px solid aliceblue;
    border-radius: 8px;
    color: #F7FBFF;
}
.arka-taraf-birimler-ust-cerceve3 {
    height: 30px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    cursor: pointer;
}