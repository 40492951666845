#cihaz {
    background-color: #42494f;
    width: 100%;
    overflow-y: scroll;
    position: relative;
}

#cihaz-grid {
    display: grid;
    width: auto;
    column-gap: 40px;
    row-gap: 20px;
    margin: 10px 30px 10px 30px;
}

.cihaz-isim {
    color: aliceblue;
    margin-top: 10px;
    margin-left:30px;
    justify-content: flex-start;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.isim-text {
    margin-left: 30px;
    font-size: 13px;
}

.icerik {
    display: flex;
    height: auto;
}

.butonDiv {
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.arka-taraf-birimler-buton2 {
    border: 1px solid #F7FBFF;
    padding: 2px 10px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-top: 5px;
    width: fit-content;
}

.ayarIcon {
    position: absolute;
    right: 0;
    top: 35%;
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 18px;
    text-align: center;
    border-radius: 4px 0 0 4px;
}

.custom-underline {
    position: relative;
    display: inline-block;
    text-align: center;
    cursor: default;
}

.custom-underline,
.custom-underline:focus,
.custom-underline:active {
    color: #fff;
    text-decoration: none;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.8);
}

.custom-underline::after {
    content: "";
    position: absolute;
}

.box-e .custom-underline::after {
    top: 100%;
    height: 2px;
    width: 0%;
    left: 5%;
    background-color: rgba(255, 255, 255, 0.6);
    transition: 0.5s ease all .5s;
}

.box-e .custom-underline::after {
    width: 90%;
    transition: 0.3s ease all;
}

.underline{
    text-decoration: underline;
}

.omnicon-alarm-ico{
    color: aliceblue;
    display: inline;
    position: -webkit-sticky;
    position: sticky;
    top:0;
    width: 30px;
    height: 30px;
    font-size: 30px;
    border: 2px solid aliceblue;
    border-radius: 50%;
    padding: 15px;
    cursor: pointer;
    z-index: 999;
}

.omnicon-lock-ico{
  color: aliceblue;
  display: block;
  position: -webkit-sticky;
  position: sticky;
  margin-left: auto;
  width: 50px;
  margin-top: -30px;
}

.omnicon-lock-ico > div {
  border: none;
}

.omnicon-lock-ico > div > .cihaz-bilgi-kart-ust-taraf-ico2 {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 30px;
  border: none;
  cursor: pointer;
  opacity: 1;
}

.rapor-alarm-text{
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  margin-right: 0px;
  margin-left: auto;
  line-height: 25px;
  align-items: flex-start;
}

.rapor-alarm-text div {
  display: flex;
  align-items: center; 
  justify-content: center; 
}

.rapor-alarm-text span {
  font-size: 14px; 
}

.omnicon-alarm-popup{
    background-color: rgba(128, 128, 128, );
    background: linear-gradient(245deg, rgb(127, 126, 126) 0%, rgb(0, 101, 184) 100%);
}

.icerik > ul {
    margin-left: -25px;
    line-height: 25px;
}
a,
a:link {
  font-family: inherit;
  text-decoration: none;
  color: inherit;
}

.modal-container {

    position: fixed;
    top: 50px;
    left: 0;
    z-index: 10;
  
    display: none;
    justify-content: center;
    align-items: center;
  
    height: 100%;
    width: 100%;
  
    background: hsla(0, 0%, 40%, .6);
  }

  .modal-container:target {
    display: flex;
  }

  .modal {
    width: 30rem;
    max-height: 75%;
    padding: 4rem 2rem;
    border-radius: .8rem;
    color: hsl(0, 0%, 100%);
    background: linear-gradient(to right bottom, hsl(236, 50%, 50%), hsl(195, 50%, 50%));
    box-shadow: .4rem .4rem 2.4rem .2rem hsla(236, 50%, 50%, 0.3);
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .modal__details {
    text-align: center;
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid hsla(0, 0%, 100%, .4);
  }
  
  .modal__title {
    font-size: 2rem;
  }

  .modal__text {
    padding: 0 2rem;
    margin-bottom: 4rem;
    font-size: 1.2rem;
    line-height: 2;

    display: flex;
    flex-direction: column;
    text-wrap: wrap;
  }

  .isim-modal__text{
    padding: 0 2rem;
    margin-bottom: 4rem;
    font-size: 1.2rem;
    line-height: 2;

    display: grid;
    grid-template-columns: auto auto auto;
    flex-direction: column;
    align-items: center;
    justify-items: start;
    justify-content: space-around;
  }
  
  .modal__text::before {
    content: '';
  
    position: absolute;
    top: 0%;
    left: 100%;
    transform: translate(-50%, -50%);
  
    width: 18rem;
    height: 18rem;
    border: 1px solid hsla(0, 0%, 100%, .2);
    border-radius: 100rem;
  
    pointer-events: none;
  }
  
  .modal__btn {
    padding: 1rem 1.6rem;
    border: 1px solid hsla(0, 0%, 100%, .4);
    border-radius: 100rem;
  
    color: inherit;
    background: transparent;
    font-size: 1.1rem;
    font-family: inherit;
    letter-spacing: .2rem;
  
    transition: .2s;
    cursor: pointer;
  }
  
  .modal__btn:hover,
  .modal__btn:focus {
    border-color: hsla(0, 0%, 100%, .6);
    background-color: seagreen;
    transform: translateY(-.2rem);
  }
  
  .sil-buton:hover, .sil-buton:focus{
    background-color: red;
  }

.link-2 {
    width: 4rem;
    height: 4rem;
    border: 1px solid hsla(0, 0%, 100%, .4);
    border-radius: 100rem;
  
    color: inherit;
    font-size: 2.2rem;
  
    position: absolute;
    top: 1rem;
    right: 1rem;
  
    display: flex;
    justify-content: center;
    align-items: center;
  
    transition: .2s;
  }
  
  .link-2::before {
    content: '×';
    transform: translateY(-.1rem);
  }
  
  .link-2:hover,
  .link-2:focus {
    border-color: hsla(0, 0%, 100%, .6);
    transform: translateY(-.2rem);
  }

  .cihaz-sil-text:hover{
    color: red;
    font-weight: bolder;
  }

  .modal-alt-icon{
    height: 190px;
    position: absolute;
    overflow: hidden;
    right: -49px;
    opacity: 0.1;
    bottom: 0;
  }

  .kullanici-kodu-input{
    background-color: transparent;
    border: 1px solid aliceblue;
    border-radius: 4px;
    width: 233px;
    font-size: 16px;
    padding: 8px;
    margin-bottom: 5px;
    color: aliceblue!important;
  }

  .kullanici-kodu-input:focus{
    outline: 1px solid aliceblue;
  }

  .cihaz-islemleri-text:hover{
    color:#55A6C7
  }

  .checkboxContainer {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .checkboxContainer input {
    position: relative;
    opacity: 0;
    cursor: pointer;
    z-index: 999;
    margin-left: -43px;
    margin-top: -1px;
    height: 25px;
    width: 25px;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: transparent;
    border: 1px solid aliceblue;
    border-radius: 3px;
  }
  
  .checkboxContainer input:checked ~ .checkmark {
    background-color: #1897ff;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .checkboxContainer input:checked ~ .checkmark:after {
    display: block;
  }

  .checkboxContainer .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  
  .checkboxContainer input:checked ~ .checkmark:first-child {
    background-color: #839aad;
  }

  .yetkilist-div{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .infopanel-kanallarDiv{ 
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .infopanel-notDiv{
    width: -webkit-fill-available;
  }
  .infopanel-notDiv2 {
    display: flex;
    justify-content: space-between;
  }

  .infopanel-buttonDiv {
    margin-top: 25px;
    display: flex;
    justify-content: center;
  }