
.nova-bilgi-kart-alt{
    width: inherit;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    
}

.nova-bilgi-kart-alt-mobil{
    width: inherit;
    display: flex;
    flex-direction: column;
    margin-left: 135px;
    row-gap: 15px;
}