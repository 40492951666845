.app{
    background-color: rgb(56, 56, 56);
    height: 100vh;
    display: grid;
    grid-template-areas: "navbar navbar" "menu icerik" ;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-family: 'Rubik', sans-serif;
    color: "#F7FBFF";
    position: relative;
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background-color: #41494F;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: #F7FBFF; 
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: #cacccf; 
  }