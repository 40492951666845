.navbar{
    background-color: #2D353C;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    grid-area: navbar;
}

.kullanici-menu{
    color: #F7FBFF;
    display: flex;
    /* width: 170px; */
    position: relative;
    margin-right: 15px;
}

#navbar-img{
    height: 35px;
    margin-left: 15px;
}

#navbar-bildirim-div{
    margin: auto 12px auto 0px; 
    position: relative;
}

#navbar-bildirim-div > div{
    background-color: red;
    border-radius: 100%;
    position: absolute;
    left: 6px;
    bottom: 10px;
    height: 18px;
    width: 18px;
    display: flex;
}

#navbar-bildirim-div > div > span{
    font-size: 10px;
    margin: auto;
    color: #F7FBFF;
}

#navbar-popup{
    background-color: #2D353C;
    position: absolute;
    top: 47px;
    right: 15px;
    color: #F7FBFF;
    border-radius: 5px;
    font-size: 12px;
    cursor: default;
    z-index: 22;
    box-shadow: rgba(0, 0, 0, 0.80) 0px 3px 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.navbar-popup-div{
    height: 80px;
    width: 270px;
    background-color: #dee1e6;
    border-radius: 5px 5px 0px 0px;
    text-align: center;
    position: relative
}
.butonddd{
    cursor: pointer;
    height: 35px;
    width: 256px;
    margin: auto auto 10px auto;
    display: flex;
    align-items: center;
    padding: 0px 0px 0px 5%
}

.profil-popup-alt-ico {
    height: 35px;
    margin: 2px auto 0px auto;
    display: flex;
    align-items: center;
    padding: 0px 0px 0px 5%
}

.butonddd:hover{
    background-color: #e3e3e4;
}

@media only screen and (max-width: 440px) {
    #navbar-img{
        height: 30px;
    }
}

.navbar-profil{
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    z-index: 21
}

.navbar-profil-ico-div{
    display:flex;
    overflow: hidden;
    justify-content: center;
    align-items: end;
    margin-right: 5px;
    width:35px;
    height:35px;
    border-radius: 100px;
    background-color: #ad42ba
}

.navbar-profil-ico{
    height: 30px;
    margin-top: 10px;
    color: #e6c6e9
}

.navbar-kullanici-adi{
    margin: 0;
    font-size: 12px;
    margin-right: 3px;
}

.profil-popup-kisi {
    color: black;
    font-size: 15px;
    font-weight: bold;
    margin-top: 20px
}

.profil-popup-gecis-div{
    height: 200px;
    width: 270px;
    background-color: white;
    border-bottom: 1px solid #c0c0c0aa;
    color: #343538;
    display: flex;
    flex-direction: column;
    align-items: center
}

.profil-popup-kullaniciAdi{
    font-size: 15px;
    line-height: 0px;
    margin: 50px 0px 0px 0px
}

.profil-popup-ico{
    color: #5f6368;
    height: 16px;
    margin-left: 4px
}

.profil-popup-ico-text{
    color: #5f6368;
    margin-left: 15px;
    font-size: 13px
}

.profil-popup-alt-div{
    border-radius: 0px 0px 5px 5px;
    width: 270px;
    background-color: white
}

.profil-popup-alt-div-diger{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    margin-left: 5%;
    margin-top: 15px;
    color: #5f5f5f
}

.profil-popup-user-ico-div {
    display:flex;
    overflow: hidden;
    justify-content: center;
    align-items: end;
    width:70px;
    height:70px;
    border-radius: 100px;
    background-color: #ad42ba;
    position: absolute;
    top: 45px;
    border: 2px solid white
}

.profil-popup-user-ico{
    height: 55px;
    margin-top: 10px;
    color: #e6c6e9
}

.profil-popup-kullaniciAdi-alt{
    color: #5f6368;
    margin-left: 10px;
    font-size: 13px
}