.anasayfa{
    background-color: #42494f;
    width: 100%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
}

.cihaz-bilgi-penceresi{
    width: 98%;
    margin-top: 10px;
    display: grid;
    gap: 10px 0px;
    margin-left: 10px;
}

.ayrintilar-git-peneresi{
    height: 40px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.45);
    position: absolute;
    bottom: 0px;
}

.ayrintilar-git-peneresi:hover{
    background-color: rgba(0, 0, 0, 0.55) !important;
    cursor: pointer;
}

.cihaz-durum-bilgisi-txt{
    font-size: 16px;
    position: absolute;
    top: 0px;
    left: 20px;
    color: rgba(255, 255, 255, 0.8);
}

.ayrintilar-git-text{
    font-size: 16px;
    color: rgba(255, 255, 255, 0.8);
    position: absolute;
    bottom: -6px;
    right: 50px;
}

.ayrintilar-git-simge{
    font-size: 20px;
    color: rgba(255, 255, 255, 0.8);
    position: absolute;
    bottom: 9px;
    right: 20px;
}

.ayrintilar-git-peneresi:hover .ayrintilar-git-text{
    color: white !important;
}

.ayrinti-genel-pencere{
    width: 95%;
    height: 180px;
    margin: auto;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
}

.simge-golge{
    font-size: 140px; 
    color: rgba(0, 0, 0, 0.7);
    position: absolute;
    bottom: 15px;
}

.simge{
    font-size: 140px;
    color: rgb(209, 209, 209);
    position: absolute;
    bottom: 24px
}

.ust-perde{
    height: 100%;
    width: 100%;
    position: absolute;
}

.cihaz-bilgi-yazisi-divi{
    height: 100%;
    width: 100%;
    display: flex;
    position: relative;
}

.cihaz-bilgi-yazisi{
    font-size: 70px;
    color: #F7FBFF;
    margin: auto;
    font-weight: lighter;
}

.starIco{
    padding-left: calc(100% - 100px)
}

@media only screen and (max-width: 1000px) {
    .ayrinti-genel-pencere{
        height: 120px;
    }
    .simge-golge{
        font-size: 90px; 
        bottom: 15px;
    }
    .simge{
        font-size: 90px;
        color: rgb(209, 209, 209);
        position: absolute;
        bottom: 20px
    }
    .cihaz-bilgi-yazisi{
        font-size: 42px;
    }
    .cihaz-durum-bilgisi-txt, .ayrintilar-git-text{
        font-size: 13px;
    }
    .ayrintilar-git-text{
        right: 35px;
    }
    .ayrintilar-git-peneresi{
        height: 30px;
    }
    .ayrintilar-git-simge{
        font-size: 16px;
        position: absolute;
        bottom: 6px;
        right: 13px;
    }
    .cihaz-bilgi-penceresi{
        grid-template-columns: 1fr 1fr;
    }
}

@media only screen and (max-width: 480px) {
    .cihaz-bilgi-penceresi{
        grid-template-columns: 1fr;
    }
}

.gradientStabil {
    background: linear-gradient(90deg, rgba(2,187,73,1) 85%, rgba(0,156,235,1) 100%)
}

.gradientPin {
    background: linear-gradient(90deg, rgba(2,187,73,1) 35%, rgba(0,156,235,1) 100%);
    border-radius: 10px;
    width: 95%;
    margin: auto;
}

.anasayfa-pinli {
    margin: 10px;
    border: 2px solid #F7FBFF;
    border-radius: 10px;
    background: linear-gradient(90deg, rgba(58,64,68,1) 0%, rgba(170,188,198,0.21332282913165268) 100%);
}
.pinli-text {
    color:aliceblue;
    margin: 15px 0px 0 15px;
}