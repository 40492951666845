.nova-bilgi-kart-paket{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.nova-bilgi-kart-paketx{
    width: 470px;
}

.nova-bilgi-kart-paket>button{
    width: 84px;
    background: linear-gradient(90deg, rgba(66,73,79,1) 0%, rgba(121,121,121,0.7763480392156863) 63%);
}
.nova-bilgi-kart-paket>.active{
    background: linear-gradient(45deg, rgba(19,126,0,0.7959558823529411) 0%, rgba(19,126,0,0.7987570028011204) 100%);
}

.nova-bilgi-kart-alt-tablet{
    width: inherit;
    display: flex;
    flex-direction: column;
    margin-left: 335px;
    row-gap: 15px;
}

.tetik-sinyal{
    display: flex;
    column-gap: 45px;
    margin-top: -55px;
}

.tetik-sinyal-tablet{
    display: flex;
    flex-direction: column;
    margin-left: -15px;
    row-gap: 5px;
}

.tetik-sinyal-mobil{
    display: flex;
    flex-direction: column;
    margin-left: -15px;
    row-gap: 5px;
}

.tetik-periyot{
    display: flex;
    margin-top: -55px;
    margin-left: -65px
}

.tetik-periyot-tablet{
    display: flex;
    flex-direction: column;
    margin-left: 0;
    row-gap: 5px;
}

.tetik-periyot-mobil{
    display: flex;
    flex-direction: column;
    row-gap: 5px;
}

.popup-prg {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.popup-prg > div {
    text-align: center;
    flex-grow: 1; 
}

.popup-prg > div > svg {
    align-self: flex-end;
    color: #F7FBFF;
    cursor: pointer;
}

.popup-proses {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
}

.popup-proses-grid{
    display: flex;
    flex-direction: row;
    column-gap: 20px;
}