.rapor-not{
    margin: 25px;
    padding: 5px;
    border: 2px solid aliceblue;
    background-color: rgb(90, 100, 110);
    display: flex;
    color: aliceblue;
    column-gap: 15px; 
    align-items: center;  
}
