.arka-taraf-potansiyostatik-buton{
    border: 1px solid #F7FBFF;
    padding: 2px 10px;
    border-radius: 2px;
    font-size: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-top: 5px;
}


.arka-taraf-potansiyostatik-cerceve{
    width: 90%;
    margin-bottom: 5px;
    min-width: 242px;
    color: #F7FBFF;
}

.arka-taraf-potansiyostatik-alt-cerceve{
    overflow: hidden;
    border-radius: 5px;
    transition: all 0.5s;
}

.arka-taraf-potansiyostatik-ust-cerceve{
    height: 30px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.arka-taraf-potansiyostatik-icerik{
    border-radius: 5px;
    width: 97%;
    margin: auto;
    font-size: 12px;
    padding-top: 5px;
}

.arka-taraf-potansiyostatik-buton-cerceve{
    display: flex;
    justify-content: flex-end;
    min-width: 242px;
}

.arka-taraf-potansiyostatik-input{
    display: flex;
    margin-bottom: 2px;
    justify-content: space-between;
    min-width: 242px;
}

.arka-taraf-potansiyostatik-input2{
    outline: none;
    border: none;
    border-radius: 3px;
    color: #F7FBFF;
    font-size: 12px;
    height: 16.5px;
    transition: all 0.5s;
}
